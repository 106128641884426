<template>
  <iframe :src="webUrl+'/admin/news/list'"></iframe>
</template>

<script>
export default {
  name: "news",
  setup(){
    return {
      "webUrl" : process.env.VUE_APP_DAILIAO_WEB_URL
    }
  }
}
</script>

<style scoped>

</style>